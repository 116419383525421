

























































.dashboard-widget-button-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
